import gql from "graphql-tag";
<template>
    <v-form ref="form1" style="width: 100%" v-model="form1Valid" lazy-validation>
        <v-autocomplete v-model="tipo" label="Tipo" placeholder=" "
                        :items="tipos" item-text="nome"
                        auto-select-first return-object
                        hide-no-data :rules="requiredRules">
            <template v-slot:item="data">
                <v-list-item-avatar color="blue darken-1">
                    <v-icon class="light-green lighten-3" v-text="getItemIcon(data.item)"></v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{data.item.nome}}</v-list-item-title>
                </v-list-item-content>
            </template>
        </v-autocomplete>
        <div class="text-right">
            <v-btn class="mr-2" text @click="cancel">Cancelar</v-btn>
            <v-btn color="primary" text @click="ok">Aplicar</v-btn>
        </div>
    </v-form>
</template>

<script>
    import gql from 'graphql-tag';
    import {getIconByFiscalizacaoTipo} from "../../../../../assets/FiscaizacaoUtil";

    export default {
        name: 'TipoForm',
        props: {
            value: null,
            opened: null,
        },
        watch: {
            opened(value) {
                if (value) {
                    this.getTipos();
                    this.tipo = this.value.data.id ? this.value.data : null;
                    /*this.before = this.value.before ? this.$moment(this.value.before).format("YYYY-MM-DD") : null;
                    this.after = this.value.after ? this.$moment(this.value.after).format("YYYY-MM-DD") : null;*/
                }
            },
        },
        data() {
            return {
                form1Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                tipo: this.value.data.id ? this.value.data : null,
                tipos: null,
            };
        },
        methods: {
            ok() {
                if (this.$refs.form1.validate()) {
                    const response = this._.cloneDeep(this.value);
                    response.data.id = this.tipo.id;
                    response.data.nome = this.tipo.nome;
                    this.$emit('input', response);
                    this.close();
                }
            },
            cancel() {
                this.$refs.form1.reset();
                this.$emit('canceled');
                this.close();
            },
            close() {
                this.$emit('update:opened', false);
            },
            getTipos() {
                this.$apollo.query({
                    query: gql`query {
                        tipos{
                            id
                            nome
                        }
                    }`
                }).then((result) => {
                    this.tipos = result.data.tipos;
                    this.tipos.unshift({id:-1,nome:'Não Definido'})
                });
            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
        },
        mounted() {
            this.getTipos();
        },
    };
</script>

<style scoped>

</style>