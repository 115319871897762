<template>
    <v-form ref="form1" style="width: 100%" v-model="form1Valid" lazy-validation>
        <v-autocomplete v-model="estado" label="Estado" placeholder=" "
                        :items="estados" item-text="nome"
                        auto-select-first return-object
                        hide-no-data :rules="requiredRules">
            <template v-slot:item="data">
                <v-list-item-avatar color="primary" class="white--text">
                    {{data.item.sigla}}
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{data.item.nome}}</v-list-item-title>
                </v-list-item-content>
            </template>
        </v-autocomplete>
        <div class="text-right">
            <v-btn class="mr-2" text @click="cancel">Cancelar</v-btn>
            <v-btn color="primary" text @click="ok">Aplicar</v-btn>
        </div>
    </v-form>
</template>

<script>
    import gql from 'graphql-tag';
    export default {
        name: 'EstadoForm',
        props: {
            value: null,
            opened: null,
        },
        watch: {
            opened(value) {
                if (value) {
                    this.getEstados();
                    this.estado = this.value.data.id ? this.value.data : null;
                }
            },
        },
        data() {
            return {
                form1Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                estado: this.value.data.id ? this.value.data : null,
                estados: null,
            };
        },
        methods: {
            ok() {
                if (this.$refs.form1.validate()) {
                    const response = this._.cloneDeep(this.value);
                    response.data.id = this.estado.id;
                    response.data.nome = this.estado.nome;
                    response.data.sigla = this.estado.sigla;
                    this.$emit('input', response);
                    this.close();
                }
            },
            cancel() {
                this.$refs.form1.reset();
                this.$emit('canceled');
                this.close();
            },
            close() {
                this.$emit('update:opened', false);
            },
            getEstados() {
                this.$apollo.query({
                    query: gql`query {
                        estados{
                            id
                            nome
                            sigla
                        }
                    }`
                }).then((result) => {
                    this.estados = result.data.estados;
                });
            },
        },
        mounted() {
            this.getEstados();
        },
    };
</script>

<style scoped>

</style>